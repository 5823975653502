import React from 'react';
import logo from './logo.svg';
import MainApp from './pages/MainApp'
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      sessionToken: window.localStorage.getItem('EXILELVLOGINTOKEN')
    };
  }

  getData() {
    fetch('http://localhost:3001/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({password: this.state.password})
    }).then( res => res.json()).then(data => {
      window.localStorage.setItem('EXILELVLOGINTOKEN', data.userToken)
      this.setState({sessionToken: data.userToken})
    })
    .catch( err => console.log(err))
  };

  render() {
    return (
      <div className="App">
          {
            this.state.sessionToken ?
              <MainApp sessionToken={this.state.sessionToken} />
            :
              <header className="App-header">
                <a href='https://discord.gg/cUAKeZp'><img className="logo" src={logo} alt='discord logo'></img></a>
                <h3 className="login-text">Use <code>!login</code> in our discord chat to get your password</h3>
                <input className="input-field" onChange={ e => this.setState({password: e.target.value})} placeholder="PASSWORD"></input>
                <button className="login-button" onClick={() => this.getData()}>LOGIN</button>
              </header>
          }
      </div>
    )
  };
}

export default App;
