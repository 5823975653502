import React from 'react';
import '../App.css';

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        points: false
    };
  }

  componentDidMount() {
      this.getUserInfo(this.props.sessionToken)
  }

  getUserInfo(sessionToken) {
      console.log(sessionToken)
    fetch('http://localhost:3001/getInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({sessionToken})
      }).then( res => res.json()).then(data => {
          console.log(data)
        this.setState({points: data.points})
      })
      .catch( err => console.log(err))
  }

  render() {
    return (
      <div className="App-header">
          <h1> WELCOME TO PAGE</h1>
          <p>{this.state.points ? this.state.points : `LOADING`}</p>
      </div>
    )
  };
}

export default MainApp;
